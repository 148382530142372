<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <Spinner :spin="spin"/>
        <section class="header activity_questions">
            <a class="icon iconButton third" @click.prevent="$router.push('/service')">
                <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
            </a>
            <span class="fL nowrap">{{ wording.title }}</span>
            <div class="icon iconButton"></div>
        </section>
        <!-- Main -->
        <section class="main activity_questions">
            <!-- Tabs -->
            <div class="tabs line fM">
                <a :class="`tab ${isOngoingTab ? 'active' : ''}`" @click="isOngoingTab = true">
                    <span>進行中</span>
                </a>
                <span class="secondary">|</span>
                <a :class="`tab ${!isOngoingTab ? 'active' : ''}`" @click="isOngoingTab = false">
                    <span>已結束</span>
                </a>
            </div>
            <div v-if="displayedCampaigns.length">
                <a
                    class="news flexH width margin between"
                    v-for="(campaign, index) in displayedCampaigns"
                    :key="'campaign'+index"
                    @click.prevent="toQRE(campaign.campaignId)"
                >
                    <div class="info flexV padding margin height">
                        <span class="fL title">{{campaign.campaignName}}</span>
                        <span class="fS gray">{{campaign.departmentName}}</span>
                        <span class="fS double">{{campaign.campaignDesc}}</span>
                        <span class="fS gray">{{tsToDate(campaign.campaignStartTime)}} - {{tsToDate(campaign.campaignEndTime)}}</span>
                    </div>
                    <div class="reward flexV padding margin center middle">
                        <!-- 即將發完 -->
                        <div class="label soon" v-if="showRewordInsufficient(campaign)">
                            <span class="fM single orange">即將發完</span>
                        </div>
                        <div class="img"><img src="@/assets/icon/point.svg"></div>
                        <!-- <span class="fM">100點</span> -->
                        <!-- 已發完 -->
                        <div class="label absolute gray" v-if="checkRewardCompleted(campaign)">
                            <span class="fM single gray center">獎勵<br/>已發完</span>
                        </div>
                        <!-- 已作答 -->
                        <div class="label absolute" v-else-if="campaign.hasCompleted">
                            <div class="icon">
                                <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon>
                            </div>
                            <span class="fM single">已參與</span>
                        </div>
                    </div>
                </a>
            </div>
            <div v-else>
                <!-- 無內容 -->
                <div class="listEmpty flexV width center middle">
                    <div class="icon secondary"><font-awesome-icon icon="fa-solid fa-dice-d6" size="10x"></font-awesome-icon></div>
                    <span v-if="isOngoingTab" class="fL gray">{{ wording.noCampaign }}</span>
                    <span v-else class="fL gray">{{ wording.noExpiredCampaign }}</span>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';

export default {
    components: {
        Spinner
    },
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaigns: [],
            spin: false,
            isOngoingTab: this.$route.params.status === 'active',
        };
    },
    mounted() {
        this.getCampaigns();
    },
    computed: {
        ...mapState(["user"]),
        displayedCampaigns() {
            const campaigns = this.campaigns.filter(({ campaignEndTime }) =>
                this.isOngoingTab
                ? this.checkCampaignIsOngoing(campaignEndTime)
                : !this.checkCampaignIsOngoing(campaignEndTime)
            );
            const sortByTsDesc = (b, a) => {
                if (a.campaignEndTime === b.campaignEndTime) {
                    return a.campaignStartTime - b.campaignStartTime;
                }
                return a.campaignEndTime - b.campaignEndTime;
            };

            return this.isOngoingTab ? campaigns : campaigns.sort(sortByTsDesc);
        },
        wording() {
            return this.$route.meta.campaignType == 'vote' 
            ? {
                title: '線上投票',
                noCampaign: '目前沒有投票議題！',
                noExpiredCampaign: '目前沒有已結束的投票',
            } 
            : {
                title: '民意調查',
                noCampaign: '目前沒有調查議題！',
                noExpiredCampaign: '目前沒有已結束的調查',
            }
        }
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        getCampaignAPI() {
            const publisher = this.$route.meta.publisher;
            const param = publisher ? `&publisherType=${publisher}` : '';
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true&includeInactive=true${param}`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            this.spin = true;
            return (
                this.getCampaignAPI()
                    .then((res) => {
                        this.campaigns = res.data.campaigns.filter(
                            campaign =>
                                campaign.campaignPrototype == this.$route.meta.campaignType
                                && campaign.campaignVisibility == 'public'
                        );
                        this.sortCampaigns();
                        console.log("campaigns: ", this.campaigns);
                    })
                    .finally(() => this.spin = false)
            );
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns);
        },
        toQRE(campaignId) {
            this.$router.push({path: `/service/qre/${campaignId}`});
        },
        checkRewardCompleted(campaign) {
            return (campaign.campaignBudget.availablePoints <= 0);
        },
        showRewordInsufficient(campaign) {
            const {
                campaignBudget: {availablePoints, usedPoints}, 
                hasCompleted, 
                campaignEndTime
            } = campaign;
            
            return ( 
                !hasCompleted && 
                this.checkCampaignIsOngoing(campaignEndTime) &&
                availablePoints > 0 &&
                availablePoints <= (availablePoints + usedPoints) * 0.05
            );
        },
        checkCampaignIsOngoing(endTime) {
            return endTime * 1000 > Date.now();
        },
    }
};
</script>
